let userData = {};

const updateLocalUser = () => {
  // console.log("updateLocalUser", userData);
  localStorage.setItem("user_data", JSON.stringify(userData));
};

const clearUser = () => {
  userData = {};
  updateLocalUser();
};

const setUserId = (uId) => {
  userData.id = uId;
  updateLocalUser();
};

const getUserId = () => {
  return userData.id;
};

const setUserGroupId = (groupId) => {
  userData.groupId = groupId;
  updateLocalUser();
};

const getUserGroupId = () => {
  return userData.groupId;
};

const setUserType = (userType) => {
  userData.type = userType;
  updateLocalUser();
};

const getUserType = () => {
  return userData.type;
};

const setUserFullName = (name) => {
  userData.fullname = name;
  updateLocalUser();
};

const getUserFullName = () => {
  return userData.fullname;
};

const setUserToken = (token) => {
  userData.token = token;
  updateLocalUser();
};

const getUserToken = () => {
  return userData.token;
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  clearUser,
  setUserId,
  getUserId,
  setUserGroupId,
  getUserGroupId,
  setUserType,
  getUserType,
  setUserFullName,
  getUserFullName,
  setUserToken,
  getUserToken,
};

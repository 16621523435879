import React, { useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  makeStyles,
  useTheme,
  Hidden,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  MenuList,
} from "@material-ui/core";
import { Menu, ExitToApp } from "@material-ui/icons";
import * as Icons from "@material-ui/icons";
import { NavLink } from "react-router-dom";
import routes from "../routes";
import UserSrv from "../providers/user.service";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";
import packageJson from "../../package.json";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      // width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    // [theme.breakpoints.up("sm")]: {
    //   display: "none",
    // },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  toolbarName: {
    textAlign: "center",
    paddingTop: "15px",
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  menuItem: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: "#000",
  },
}));

function AppNavHeader(props) {
  const { window } = props;
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const logoutFn = () => {
    UserSrv.clearUser();
    handleDrawerToggle();
    history.push("/login");
  };

  // const navigatePath = (path) => {
  //   props.history.push(path);
  // };

  useEffect(() => {
    console.log("route has been changed", location.pathname);
    // ...your code
  }, [location.pathname]);

  // history.listen((location, action) => {
  //   // location is an object like window.location
  //   console.log(action, location.pathname, location.state);
  // });

  const drawer = (
    <div>
      <div className={[classes.toolbar, classes.toolbarName].join(" ")}>
        <div>Instasac</div>
        <div>
          <small>{`V${packageJson.version} - Beta`}</small>
        </div>
      </div>
      <Divider />
      <MenuList>
        {routes.map((route, index) => {
          if (
            route.inMenu &&
            route.groupIds.indexOf(UserSrv.getUserGroupId()) > -1
          ) {
            return (
              <NavLink
                to={route.path}
                className={classes.menuItem}
                onClick={handleDrawerToggle}
                key={route.pagename}
              >
                <ListItem button key={route.pagename}>
                  <ListItemIcon>
                    {React.createElement(Icons[route.pageicon])}
                  </ListItemIcon>
                  <ListItemText primary={route.pagename} />
                </ListItem>
              </NavLink>
            );
          }
        })}
      </MenuList>
      <Divider />
      <List>
        {/* {["Logout"].map((text, index) => ( */}
        <ListItem button key="logout" onClick={logoutFn}>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
        {/* ))} */}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    location.pathname !== "/login" && (
      <div>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <Menu />
            </IconButton>
            {/* <Typography variant="h6" noWrap>
            
          </Typography> */}
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer} aria-label="mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          {/* <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden> */}
        </nav>
      </div>
    )
  );
}

export default AppNavHeader;

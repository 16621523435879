import React from "react";
// import UserSrv from "../../providers/user.service";

export default function Home() {
  return (
    <React.Fragment>
      <h1 style={{ textAlign: "center", padding: "16px" }}>
        Welcome to Instasac Dashboard
      </h1>
    </React.Fragment>
  );
}

import React, { Component } from "react";
import "./login.page.scss";
import ApiSrv from "../../providers/api.service";
import UserSrv from "../../providers/user.service";
import {
  TextField,
  Button,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
// import { LoginJSX } from "./login.tpl";
import * as CryptoJS from "crypto-js";

export default class Login extends Component {
  state = {
    username: "",
    password: "",
    errorMsg: "",
    loading: false,
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  signIn = async () => {
    // console.log(this.state);
    try {
      const hPassword = CryptoJS.AES.encrypt(
        this.state.password,
        "mySecretPassphrasePWD"
      ).toString();
      this.setState({
        loading: true,
      });
      const res = await ApiSrv.makeApi("auth/user", "GET", {
        query: [this.state.username, hPassword],
      });
      // console.log(res);
      if (res.response && res.response.user_id) {
        // SHOULD WE VERIFY status AND status_code ???
        UserSrv.setUserId(res.response.user_id);
        UserSrv.setUserGroupId(res.response.user_group_id);
        UserSrv.setUserType(res.response.user_type);
        UserSrv.setUserFullName(res.response.user_fullname);
        UserSrv.setUserToken(res.response.token);
        this.props.history.push("/");
      }
    } catch (error) {
      // console.log(error);
      if (error.message) {
        this.setState({
          errorMsg: error.message,
        });
      }
    } finally {
      this.setState({
        loading: false,
      });
    }
  };
  render() {
    return (
      <React.Fragment>
        <div className="login-container">
          <form>
            <h1>Instasac Dashboard</h1>
            <hr />
            <div className="login-inputs">
              <TextField
                id="usernameId"
                name="username"
                className="login-input username"
                label="Username"
                variant="outlined"
                fullWidth
                value={this.state.username}
                onChange={this.handleChange}
              />
              <TextField
                id="passswordId"
                name="password"
                className="login-input password"
                type="password"
                label="Password"
                variant="outlined"
                fullWidth
                value={this.state.password}
                onChange={this.handleChange}
              />
            </div>
            <div
              style={{ padding: "10px", color: "#f44336", textAlign: "center" }}
            >
              {this.state.errorMsg}
            </div>
            <div className="login-buttons">
              <Button variant="outlined" fullWidth onClick={this.signIn}>
                Login
              </Button>
            </div>
          </form>
        </div>
        <Backdrop
          style={{ zIndex: "999999999999", color: "#fff" }}
          open={this.state.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </React.Fragment>
    );
  }
}

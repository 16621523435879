// import React from "react";
import axios from "axios";
import * as CryptoJS from "crypto-js";
import UserSrv from "./user.service";
import packageJson from "../../package.json";

const makeApi = async (apiRoute, method, params = {}) => {
  try {
    // const baseUrl = process.env.REACT_APP_API_URL;
    // const baseUrl = "http://localhost:3000/in-api/";
    // const baseUrl = "https://testapi.instasac.com/in-api/";
    const baseUrl = "https://api.instasac.com/in-api/";
    let paramsQuery = params.query || []; // Make sure you're sending in the correct order
    const paramsBody = params.body || {}; // JUST FOR POST, PUT, PATCH
    const paramsQueryOptions = params.queryOptions || {}; // JUST FOR GET

    paramsQuery = paramsQuery.map((param) => encodeURIComponent(param));
    const apiEndPoint = `${baseUrl + apiRoute}/${paramsQuery.join("/")}`;

    const customParamsHeader = {
      platform: "web",
      // lang: this.langSrv.getLang(),
      lang: "en",
      version: `${packageJson.version}`,
    };

    if (
      UserSrv.getUserToken() &&
      UserSrv.getUserId() &&
      UserSrv.getUserGroupId()
    ) {
      customParamsHeader.userId = UserSrv.getUserId();
      customParamsHeader.groupId = UserSrv.getUserGroupId();
    }

    // custom header
    let headers = {
      "ins-g-params": JSON.stringify(customParamsHeader),
    };

    if (UserSrv.getUserToken()) {
      headers["Authorization"] = `Bearer ${UserSrv.getUserToken()}`; // Header Authorization Bearer
    }

    let bodyData = {};
    for (const key in paramsBody) {
      if (paramsBody.hasOwnProperty(key)) {
        bodyData[key] = paramsBody[key];
      }
    }

    let queryStringParams = {};
    if (method === "GET") {
      if (paramsQueryOptions.pageNb) {
        queryStringParams["pageNb"] = paramsQueryOptions.pageNb.toString();
      }
      if (paramsQueryOptions.pageLimit) {
        queryStringParams[
          "pageLimit"
        ] = paramsQueryOptions.pageLimit.toString();
      }
      if (paramsQueryOptions.orderBy) {
        queryStringParams["orderBy"] = paramsQueryOptions.orderBy.toUpperCase();
      }
      if (paramsQueryOptions.sortBy) {
        queryStringParams["sortBy"] = paramsQueryOptions.sortBy.toUpperCase();
      }
      if (paramsQueryOptions.searchTerm) {
        queryStringParams[
          "searchTerm"
        ] = paramsQueryOptions.searchTerm.toUpperCase();
      }
    }

    const reply = await axios({
      method,
      url: apiEndPoint,
      headers,
      data: bodyData,
      withCredentials: true,
      params: paramsQueryOptions,
    });
    if (reply.data && reply.data.response) {
      // console.log("reply.data.response ", reply.data.response);
      reply.data.response =
        CryptoJS.AES.decrypt(
          reply.data.response,
          "mySecretPassphraseRES"
        ).toString(CryptoJS.enc.Utf8) || reply.data.response;
      try {
        reply.data.response = JSON.parse(reply.data.response);
      } catch (e) {
        // continue
      }
    }
    return Promise.resolve(reply.data);
  } catch (error) {
    const errordata =
      error.response && error.response.data
        ? error.response.data
        : "Something went wrong!";
    return Promise.reject(errordata);
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  makeApi,
};

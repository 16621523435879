import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Table,
  IconButton,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import ApiSrv from "../../providers/api.service";
import { Edit, ArtTrack, Category } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

const columns = [
  { id: "seller_id", label: "ID", minWidth: 10 },
  { id: "sellername", label: "Name", minWidth: 100 },
  { id: "group_name", label: "Group", minWidth: 100 },
  { id: "approved", label: "Approved", minWidth: 10 },
  { id: "published", label: "Published", minWidth: 10 },
];

// function createData(name, code, population, size) {
//   const density = population / size;
//   return { name, code, population, size, density };
// }

// let rows = [
//   createData("India", "IN", 1324171354, 3287263),
//   createData("China", "CN", 1403500365, 9596961),
//   createData("Italy", "IT", 60483973, 301340),
//   createData("United States", "US", 327167434, 9833520),
// ];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    // maxHeight: 440,
    maxHeight: "calc(100vh - 116px)",
  },
  backdrop: {
    zIndex: 999999999999,
    color: "#fff",
  },
});

export default function Merchants() {
  const history = useHistory();
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [data, setData] = React.useState([]);
  const [totalRows, setTotalRows] = React.useState(0);
  const [loading, setLoading] = React.useState(true);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const showMerchantDetails = () => {
    console.log("showMerchantDetails");
  };

  const showMerchantProducts = (seller_id) => {
    history.push(`/merchant-products/${seller_id}`);
  };

  const showMerchantCategories = (seller_id) => {
    history.push(`/merchant-categories/${seller_id}`);
  };

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        const res = await ApiSrv.makeApi("merchant", "GET", {
          queryOptions: {
            pageNb: page + 1,
            pageLimit: rowsPerPage,
          },
        });
        // console.log(res);
        setData(res.response.merchants);
        setTotalRows(res.response.total_rows);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
    loadData();
  }, [page, rowsPerPage]);

  return (
    <div>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                      <TableCell key="actions">
                        {/* <IconButton onClick={showMerchantDetails}>
                          <Edit color="primary" fontSize="small" />
                        </IconButton> */}
                        <IconButton
                          value={row.seller_id}
                          onClick={() => showMerchantProducts(row.seller_id)}
                        >
                          <ArtTrack color="primary" fontSize="small" />
                        </IconButton>
                        <IconButton
                          value={row.seller_id}
                          onClick={() => showMerchantCategories(row.seller_id)}
                        >
                          <Category color="primary" fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

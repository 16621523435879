const routes = [
  {
    path: "/",
    component: require("./pages/home/home.page").default,
    pagename: "Home",
    pageicon: "HomeOutlined",
    inMenu: true,
    groupIds: [1, 6, 3, 4],
  },
  {
    path: "/merchants",
    component: require("./pages/merchants/merchants.page").default,
    pagename: "Merchants",
    pageicon: "StorefrontOutlined",
    inMenu: true,
    groupIds: [1, 6],
  },
  {
    path: "/merchant-products/:seller_id/:category_id?",
    component: require("./pages/merchants/merchant-products.page").default,
    pagename: "Merchant Products",
    pageicon: "StorefrontOutlined",
    inMenu: false,
  },
  {
    path: "/merchant-categories/:seller_id",
    component: require("./pages/merchants/merchant-categories.page").default,
    pagename: "Merchant Categories",
    pageicon: "CategoryOutlined",
    inMenu: false,
  },
  {
    path: "/categories",
    component: require("./pages/merchants/merchant-categories.page").default,
    pagename: "Categories",
    pageicon: "CategoryOutlined",
    inMenu: true,
    groupIds: [3, 4],
  },
  {
    path: "/products",
    component: require("./pages/merchants/merchant-products.page").default,
    pagename: "Products",
    pageicon: "SubjectOutlined",
    inMenu: true,
    groupIds: [3, 4],
  },
];
export default routes;

import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.scss";
import Login from "./pages/login/login.page";
import routes from "./routes";
import UserSrv from "./providers/user.service";
import AppNavHeader from "./components/nav-header.comp";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";
function App() {
  const location = useLocation();
  const history = useHistory();
  // const [pathname, setPathname] = React.useState("");
  try {
    if (!localStorage.getItem("user_data")) {
      localStorage.setItem("user_data", "");
      history.push("/login");
    } else {
      const localUserData =
        JSON.parse(localStorage.getItem("user_data")) || false;
      // console.log(localUserData);
      if (localUserData && localUserData.token) {
        UserSrv.setUserId(localUserData.id);
        UserSrv.setUserGroupId(localUserData.groupId);
        UserSrv.setUserType(localUserData.type);
        UserSrv.setUserFullName(localUserData.fullname);
        UserSrv.setUserToken(localUserData.token);
      } else {
        localStorage.setItem("user_data", "");
        console.log("111");
        history.push("/login");
      }
    }
  } catch (error) {
    localStorage.setItem("user_data", "");
    console.log("222", error);
    history.length = 0;
    // history.push("/login");
  }

  // useEffect(() => {
  //   console.log("route has been changed", location.pathname);
  //   // ...your code
  //   // setPathname(location.pathname);
  //   // console.log(pathname);
  // }, [location.pathname, history]);
  return (
    <Router basename="/">
      <div className="App">
        {/* {location.pathname !== "/login" && <AppNavHeader routes={routes} />}
         */}
        <AppNavHeader routes={routes} />
        <div style={{ marginTop: 64 }}>
          {/* <div style={{ marginTop: location.pathname === "/login" ? 0 : 64 }}> */}
          <Switch>
            <Route exact path="/login" component={Login} />
            {routes.map((route, i) => {
              return (
                // <div>
                <Route
                  exact
                  path={route.path}
                  component={route.component}
                  key={i}
                >
                  {/* <route.component/> */}
                </Route>
              );
            })}
          </Switch>
        </div>
      </div>
    </Router>
  );
}
export default App;

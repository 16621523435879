import React, { useEffect, useCallback } from "react";
import {
  makeStyles,
  Modal,
  Paper,
  Table,
  IconButton,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Snackbar,
  Backdrop,
  CircularProgress,
  InputBase,
  Divider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  List,
  ListItem,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import ApiSrv from "../../providers/api.service";
import userSrv from "../../providers/user.service";
import { Edit, Search, ExpandMore } from "@material-ui/icons";
import { useParams } from "react-router-dom";
// import sharedSrv from "../../providers/shared.service";

const columns = [
  // { id: "product_id", label: "ID", minWidth: 10 },
  // { id: "image", label: "Image", minWidth: 10 },
  { id: "name", label: "Name", minWidth: 100 },
  { id: "product_name_ar", label: "Arabic Name", minWidth: 100 },
  // { id: "description", label: "Desc", minWidth: 100, maxWidth: 150 },
  { id: "price", label: "Price", minWidth: 100 },
  { id: "unit", label: "Unit", minWidth: 100 },
  { id: "unit_price", label: "U. Price", minWidth: 100 },
  {
    id: "in_stock",
    label: "In Stock",
    minWidth: 5,
    valueGetter: (params) => `${params.getValue("in_stock") ? "Y" : "N "}`,
  },
  { id: "is_active", label: "Active", minWidth: 5 },

  //   description: ""
  // image: "products/1.jpg"
  // is_active: 1
  // manufacturer_id: 1
  // name: "Plastic Cups"
  // pack_no: null
  // price: 2000
  // product_id: 1
  // seller_id: 1
  // status: 1
  // unit: "Pcs"
  // unit_price: null
  // unit_price_factor: 1
  // unit_value: "100"
];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  searchContainer: {
    backgroundColor: "#fafafa",
    padding: "5px 0",
    "& .searchBox": {
      padding: "2px 4px",
      display: "flex",
      alignItems: "center",
      boxShadow:
        "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
      backgroundColor: "#fff",
      width: "280px",
      paddingRight: "8px",
      marginLeft: "10px",
      "& .input": {
        marginLeft: "8px",
        flex: 1,
      },
      "& .iconButton": {
        padding: 10,
      },
      "& .divider": {
        height: 28,
        margin: 4,
      },
    },
  },
  container: {
    maxHeight: "calc(100vh - 174px)",
  },
  backdrop: {
    zIndex: 999999999999,
    color: "#fff",
  },
});

const useModalStyles = makeStyles({
  root: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFF",
    position: "absolute",
    padding: "10px 5px",
    width: "80vw",
    outline: "none",
    maxWidth: "800px",
    maxHeight: "80vh",
    overflowY: "auto",
    "& .MuiTextField-root, & .MuiFormControlLabel-root": {
      margin: "10px",
      width: "25ch",
    },
    "& .MuiAccordionDetails-root": {
      padding: "5px",
    },
  },
  productName: {
    padding: "10px 5px",
    width: "100%",
    margin: "auto 5px",
  },
  accordions: {
    marginBottom: "25px",
  },
  accItemList: {
    width: "100%",
    "& .MuiListItem-root": {
      padding: "0",
      justifyContent: "space-between",
    },
    "& .MuiTextField-root": {
      maxWidth: "6em",
    },
    "& .optionItemName": {
      padding: "5px 0",
      flex: "1",
    },
  },
});
// function getModalStyle() {
//   const top = 50;
//   const left = 50;

//   return {
//     top: `${top}%`,
//     left: `${left}%`,
//     transform: `translate(-${top}%, -${left}%)`,
//     backgroundColor: "#FFF",
//     position: "absolute",
//     padding: "10px 5px",
//     width: "80vw",
//     maxWidth: "800px",
//   };
// }

export default function MerchantProducts() {
  const params = useParams();
  const merchantId =
    userSrv.getUserGroupId() === 3 || userSrv.getUserGroupId() === 4
      ? userSrv.getUserId()
      : params.seller_id;
  const category_id = params.category_id || -1;
  const classes = useStyles();
  const modalClasses = useModalStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [data, setData] = React.useState([]);
  const [totalRows, setTotalRows] = React.useState(0);
  const [open, setOpen] = React.useState(false, {});
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [modalData, setModalData] = React.useState({});
  // const [modalStyle] = React.useState(getModalStyle);
  const [modalErrorMsg, setModalErrorMsg] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [accExpanded, setAccExpanded] = React.useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearch = () => {
    // console.log(searchTerm);
    // setPage(0);
    loadData(true);
  };
  const handleOpen = async (productData) => {
    // console.log(productData);
    setModalErrorMsg("");
    if (productData.options_count) {
      try {
        setLoading(true);
        const productOption = await ApiSrv.makeApi(
          `merchant/${merchantId}/productOptions/${productData.product_id}`,
          "GET"
        );
        // console.log(productOption);
        if (productOption.response && productOption.response.options) {
          productData.options = productOption.response.options;
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }

    // console.log(productData);
    setModalData(productData);
    setOpen(true);
  };

  const handleClose = () => {
    setModalErrorMsg("");
    setOpen(false);
  };

  const handleAccChange = (panel) => (event, isExpanded) => {
    setAccExpanded(isExpanded ? panel : false);
  };

  const handleOnChangeOptionItem = (
    currOptionGroupId,
    currOptionItemId,
    newPrice,
    newIsActive
  ) => {
    setModalData((old) => ({
      ...old,
      // options: old.options,
      options: old.options.map((oldOption) => {
        if (oldOption.option_group_id === currOptionGroupId) {
          oldOption.items.map((oldItem) => {
            if (oldItem.option_item_id === currOptionItemId) {
              // console.log(oldItem);
              oldItem.updated = true;
              oldItem.price = Number(newPrice);
              oldItem.is_active = Number(newIsActive);
              // console.log(oldItem);
            }
          });
        }
        // console.log(option1);
        return oldOption;
      }),
    }));
  };
  const updateProduct = async () => {
    try {
      setLoading(true);

      let updatedOptions = [];
      if (modalData.options && modalData.options.length) {
        for (const option of modalData.options) {
          for (const item of option.items) {
            if (item.updated) {
              updatedOptions.push({
                option_group_id: option.option_group_id,
                option_item_id: item.option_item_id,
                price: item.price,
                is_active: item.is_active,
              });
            }
          }
        }
      }
      // console.log(updatedOptions);
      await ApiSrv.makeApi(
        `merchant/${merchantId}/product/${modalData.product_id}`,
        "PUT",
        {
          body: {
            price: modalData.price,
            is_active: modalData.is_active,
            in_stock: modalData.in_stock,
            unit_price: modalData.unit_price || null,
            updatedOptions: updatedOptions.length ? updatedOptions : false,
          },
        }
      );
      const updatedData = data.map((item) => {
        if (item.product_id === modalData.product_id) {
          item = modalData;
        }
        return item;
      });
      setData(updatedData);
      setOpenSnackBar(true);
      handleClose();
    } catch (error) {
      setModalErrorMsg(error.message);
    } finally {
      setLoading(false);
    }
  };

  // const handleChange = (event, value) => {
  //   console.log(event);
  //   console.log(value);
  // };

  // const loadData = useCallback(async () => {
  //   try {
  //     setLoading(true);
  //     const res = await ApiSrv.makeApi(
  //       `merchant/${merchantId}/products`,
  //       "GET",
  //       {
  //         queryOptions: {
  //           pageNb: page + 1,
  //           pageLimit: rowsPerPage,
  //           searchTerm,
  //         },
  //       }
  //     );
  //     console.log(res.response);
  //     setData(res.response.products);
  //     setTotalRows(res.response.total_rows);
  //   } catch (error) {
  //   } finally {
  //     setLoading(false);
  //   }
  // }, []);
  const loadData = async (newSearch = false) => {
    try {
      setLoading(true);
      if (newSearch) {
        setPage(0);
      }
      const res = await ApiSrv.makeApi(
        `merchant/${merchantId}/products/${category_id}`,
        "GET",
        {
          queryOptions: {
            pageNb: page + 1,
            pageLimit: rowsPerPage,
            searchTerm,
          },
        }
      );

      setData(res.response.products);
      setTotalRows(res.response.total_rows);
    } catch (error) {
      setData([]);
      setTotalRows(0);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // sharedSrv.showLoader();
    loadData();
  }, [merchantId, page, rowsPerPage]);

  return (
    <div>
      <Paper className={classes.root}>
        <div className={classes.searchContainer}>
          <div className="searchBox">
            <InputBase
              className="input"
              placeholder="Search"
              type="search"
              inputProps={{ "aria-label": "Search" }}
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
              onKeyPress={(data) => {
                if (data.charCode === 13) {
                  handleSearch();
                }
              }}
            />
            <Divider className="divider" orientation="vertical" />
            <IconButton
              type="submit"
              className="iconButton"
              aria-label="search"
              onClick={() => handleSearch()}
            >
              <Search />
            </IconButton>
          </div>
        </div>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                      <TableCell key="actions">
                        <IconButton onClick={() => handleOpen(row)}>
                          <Edit color="primary" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={modalClasses.root}>
          <form className={classes.root} noValidate autoComplete="off">
            {/* <TextField
              id="productNameId"
              label="Name"
              variant="outlined"
              value={modalData.name}
              disabled
              InputProps={{
                readOnly: true,
              }}
            /> */}
            <div className={modalClasses.productName}>
              #{modalData.product_id} : <b>{modalData.name}</b>
            </div>
            {(!modalData.unit_price_factor ||
              modalData.unit_price === null) && (
              <div id="withoutFactor">
                <TextField
                  id="productPriceId"
                  label="Price"
                  type="number"
                  variant="outlined"
                  value={modalData.price}
                  disabled={!modalData.price}
                  onChange={(e) => {
                    setModalData((old) => ({ ...old, price: e.target.value }));
                  }}
                />
              </div>
            )}
            {modalData.unit_price_factor && modalData.unit_price !== null && (
              <div id="withFactor">
                <TextField
                  id="unitPriceId"
                  label={`Unit Price (${
                    modalData.unit_value / modalData.unit_price_factor
                  } ${modalData.unit})`}
                  type="number"
                  variant="outlined"
                  value={modalData.unit_price}
                  onChange={(e) => {
                    //replace non-digits with blank
                    const value = e.target.value.match(/^(\s*|\d+)$/, "");
                    if (value) {
                      setModalData((old) => ({
                        ...old,
                        unit_price: e.target.value,
                        price: e.target.value * old.unit_price_factor,
                      }));
                    }
                  }}
                />
                <TextField
                  id="unitFactorId"
                  label="Unit Factor"
                  variant="filled"
                  value={modalData.unit_price_factor}
                  disabled
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <TextField
                  id="priceId"
                  label={`Display Price (${modalData.unit_value} ${modalData.unit})`}
                  variant="filled"
                  value={modalData.price}
                  disabled
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
            )}
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={modalData.is_active === 1}
                    onChange={(e) => {
                      setModalData({
                        ...modalData,
                        [e.target.name]: e.target.checked | 0,
                      });
                    }}
                    name="is_active"
                  />
                }
                label="Active"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={modalData.in_stock === 1}
                    onChange={(e) => {
                      setModalData({
                        ...modalData,
                        [e.target.name]: e.target.checked | 0,
                      });
                    }}
                    name="in_stock"
                  />
                }
                label="In Stock"
              />
            </div>
            {modalData.options && modalData.options.length && (
              <div className={modalClasses.accordions}>
                {modalData.options.map((option) => {
                  const optionKey = `option_${merchantId}_${modalData.product_id}_${option.option_group_id}`;
                  return (
                    <Accordion
                      expanded={accExpanded === optionKey}
                      onChange={handleAccChange(optionKey)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls={`${optionKey}-content`}
                        id={`${optionKey}-header`}
                      >
                        <Typography>
                          <span>#{option.option_group_id}: </span>
                          {option.text}
                          {option.required === 1 && (
                            <span style={{ color: "#f50057" }}>
                              {" "}
                              (Required)
                            </span>
                          )}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <List className={modalClasses.accItemList}>
                          {option.items &&
                            option.items.length &&
                            option.items.map((optionItem) => {
                              return (
                                <div>
                                  <ListItem>
                                    <Checkbox
                                      checked={optionItem.is_active === 1}
                                      onChange={(e) => {
                                        // setModalData({
                                        //   ...modalData,
                                        //   [e.target.name]: e.target.checked | 0,
                                        // });
                                        handleOnChangeOptionItem(
                                          optionItem.option_group_id,
                                          optionItem.option_item_id,
                                          optionItem.price,
                                          e.target.checked | 0
                                        );
                                      }}
                                      name="is_active"
                                    />
                                    <span className="optionItemName">
                                      {optionItem.text}
                                    </span>
                                    {optionItem.show_price === 1 && (
                                      <TextField
                                        label="Price"
                                        id={`option_item_${optionItem.option_group_id}_${optionItem.option_item_id}`}
                                        value={optionItem.price}
                                        variant="outlined"
                                        size="small"
                                        onChange={(e) => {
                                          //replace non-digits with blank
                                          const value = e.target.value.match(
                                            /^(\s*|\d+)$/,
                                            ""
                                          );
                                          if (value) {
                                            handleOnChangeOptionItem(
                                              optionItem.option_group_id,
                                              optionItem.option_item_id,
                                              e.target.value,
                                              optionItem.is_active
                                            );
                                          }
                                        }}
                                      />
                                    )}
                                  </ListItem>
                                  <Divider />
                                </div>
                              );
                            })}
                        </List>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </div>
            )}
            <div>{modalErrorMsg}</div>
            <div>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                onClick={updateProduct}
              >
                Submit
              </Button>
            </div>
          </form>
        </div>
      </Modal>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackBar(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Alert onClose={() => setOpenSnackBar(false)} severity="success">
          Data has been updated!
        </Alert>
      </Snackbar>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
